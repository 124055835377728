<template>
	<div class="links-wrap">
		<div v-for="entity in entities" :key="entity._id" class="link">
			<router-link
				v-if="entityType == 'artists'"
				:to="{ name: 'Artist', params: { namePath: entity.namePath, category: entity.category } }"
				:class="entity.level == 1 ? 'level-1' : ''"
			>
				{{
					entity.lastName ? entity.lastName + (entity.firstName ? ', ' + entity.firstName : '') : entity.name
				}}
			</router-link>

			<router-link
				v-else-if="entityType == 'users'"
				:to="{ name: 'User', params: { username: entity.username } }"
				>{{ entity.name }}</router-link
			>

			<small>{{ entity.artworkCount }}</small>
		</div>
	</div>
</template>

<script>
import { injectMetaData } from '@/use/MetaData'
import { titlefy, singular } from '@/use/StringMagic'

export default {
	name: 'CatalogIndexList',
	props: {
		entities: Object,
		entityType: String,
	},
	setup(props) {
		injectMetaData({
			title: `Arthur.io • ${titlefy(singular(props.entityType))} Index`,
		})
	},
}
</script>

<style lang="scss" scoped>
/* Wrap */
.links-wrap {
	// (250 x 5) + (40 x 3) + 120
	max-width: 14.9rem;
	margin: 0 auto;
	margin-bottom: 2rem;
	column-count: 5;
	column-gap: 0.4rem;
	padding: 0.6rem;
	min-height: 100%;
	font-size: 0;
	line-height: 0;
}

/* Links */
.links-wrap .link {
	display: flex;
	height: 0.3rem;
	width: 100%;
	font-size: $regular;
	line-height: 0.3rem;
	position: relative;
	border-radius: $br;
	padding: 0 0.1rem;
	margin: 0 -0.1rem;

	// background: #eed;
	// border: solid 1px red;
}
.links-wrap .link a {
	flex: 1 1;
	color: $black;
	// background: pink;

	// Truncate
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.links-wrap .link small {
	flex: 0 1;
	line-height: 0.33rem;
	text-align: right;
	margin-left: 0.1rem;
	color: $black-30;
	text-align: right;
	// background: yellow;
}
// .links-wrap a:not(.level-1) {
// 	opacity: 0.3;
// }

@media (hover: hover) {
	.links-wrap .link:hover {
		background: $white;
		// background: #eed;
		// border: solid 1px $black-10;
	}
	.links-wrap .link:hover a {
		color: $primary-dark;
	}
	.links-wrap .link:hover small {
		color: $black;
	}
}

/**
 * Responsive logic is based on a minimum column width of 200px
 * and a maximum column width of 
 */

// 4 columns
@media only screen and (max-width: $list-cols-4) {
	.links-wrap {
		// Limit colum width to 250
		// (250 x 4) + (40 x 3) + 120 = 1240
		max-width: 12.4rem;
		column-count: 4;
	}
}

// 3 columns
@media only screen and (max-width: $list-cols-3) {
	.links-wrap {
		// Limit colum width to 250
		// (250 x 3) + (40 x 2) + 120 = 950
		max-width: 9.5rem;
		column-count: 3;
	}
}

// 2 columns
@media only screen and (max-width: $list-cols-2) {
	.links-wrap {
		// Limit colum width to 250
		// (250 x 2) + 40 + 120 = 660
		max-width: 6.6rem;
		column-count: 2;
	}
}

// 1 column
@media only screen and (max-width: $list-cols-1) {
	.links-wrap {
		column-count: 1;
	}
}
</style>
